/* DEFAULT STYLE REMOVAL */

body {
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}

a {
    text-decoration: black underline;
}

input,
button {
  background-color: none;
  border: none;
  padding: none;
  margin: none;
  outline: none;
}

button:hover {
  cursor: pointer;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* CUSTOM STYLES */

#primaryCanvas {
  background-color: lightgray;
}

@media screen and (max-width: 900px) {
  /* MOBILE */
  .app-container {
    display: flex;
    flex-direction: column;
  }
}

.statistics-section {
  margin-bottom: 1rem;
}

.age-group-statistic-row {
  margin-bottom: 1rem;
}

.age-group-statistic-row:last-child {
  margin-bottom: 0;
}

.simulate-start-button {
  background-color: #257a2b;
  color: white;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid black;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
}

.input-number-clicker-button {
  background-color: dimgray;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.input-number-clicker-button:hover {
    background-color: gray;
}

.input-number-clicker-button:active {
    background-color: white;
    color: dimgray;
}

.preset-option-button {
    color: white;
    background-color: dimgray;
}

.preset-option-button:hover {
    background-color: gray;
}

.preset-option-button:active {
    background-color: white;
    color: dimgray;
}

.pointer-mouseover {
    cursor: pointer;
}

@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.3; }
    100% { opacity:1; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.3; }
    100% { opacity:1; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.3; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.3; }
    100% { opacity:1; }
  }
  .animate-flicker {
     -webkit-animation: flickerAnimation 1.4s infinite;
     -moz-animation: flickerAnimation 1.4s infinite;
     -o-animation: flickerAnimation 1.4s infinite;
      animation: flickerAnimation 1.4s infinite;
  }
